import moment from "moment";
import { INSP_MOBILE_STATUS, SALESFORCE_ENTITY_TYPE, ENDPOINTS } from "../appConsts";




export const buildLinkIntoSalesforce = (salesforceEntity, salesforceId) => {

	switch (salesforceEntity) {
		case SALESFORCE_ENTITY_TYPE.CUSTOMER:
			return ENDPOINTS.SALESFORCE_BASE_URL + SALESFORCE_ENTITY_TYPE.CUSTOMER + '/' + salesforceId + '/view';

		case SALESFORCE_ENTITY_TYPE.BUILDING:
			return ENDPOINTS.SALESFORCE_BASE_URL + SALESFORCE_ENTITY_TYPE.BUILDING + '/' + salesforceId + '/view';

		case SALESFORCE_ENTITY_TYPE.INVOICE:
			return ENDPOINTS.SALESFORCE_BASE_URL + SALESFORCE_ENTITY_TYPE.INVOICE + '/' + salesforceId + '/view';

		default:
			return null;
	}

};




export const formatCurrency = (item) => {

	// check for null
	if (item === null) return '$0.00';
	if (item === undefined) return '$0.00';
	if (item === '') return '$0.00';

	if (!isNaN(item)) {
		// it is a number
		let rawNumber = parseInt(item, 10);
		return '$' + rawNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	} else {
		return '$0.00';
	}
};


export function groupBy(list, keyGetter) {
	const map = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);

		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}



export const formatDateLong = (value) => {
	if (value !== "" && value !== undefined) {
		//let test = moment(value).format("M/D/YYYY h:mm:ss A");

		return moment(value).format("M/D/YYYY h:mm:ss A");
	}
	return null;
};

export const formatDateShort = (value) => {
	if (value !== "" && value !== undefined) {

		return moment(value).format("M/D/YYYY");
	}
	return null;
};


export const boolToYesNo = (value) => {

	if (value === null) return "No";

	return value === false ? "No" : "Yes";
};


export const splitPhone = (e) => {

	// remove (125) 458-5555 and empty spaces from phone number.
	// we need to return 3 items xxx xxx and xxxx
	let ph1 = e.slice(1, 4);
	let ph2 = e.slice(6, 9);
	let ph3 = e.slice(10, 14);


	//let fullyFormattedNumber = "(" + ph1 + ") " + ph2 + "-" + ph3;

	return { phone1: ph1, phone2: ph2, phone3: ph3 };
};

export const formatPhone = (e) => {

	// we need to return a (222) 222-2222

	var string1 = e.replace("(", "");
	var string2 = string1.replace(")", "");
	var string3 = string2.replace("-", "");
	var string4 = string3.replace(" ", "");



	return string4;
};


export const phoneMask = () => {


	return [
		'(',
		/[1-9]/,
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	];
};





export function mapInspectorDropDown(list) {

	let returnList = list.map((item) => {
		return {
			value: item.userId,
			label: item.fullNameLastFirst
		};
	});

	return returnList;
}


export function mapMobileStatus(value) {

	switch (value) {
		case INSP_MOBILE_STATUS.TODO.ID:
			return INSP_MOBILE_STATUS.TODO.VALUE;

		case INSP_MOBILE_STATUS.COMPLETE.ID:
			return INSP_MOBILE_STATUS.COMPLETE.VALUE;

		//todo: configure unassigned
		default:
			return null;

	}

}


