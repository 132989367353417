import { INSPECTION_SEARCH as ActionTypes } from '../actionTypes';
import * as inspectionApi from '../../services/inspectionApi';

export function setSearchFilter(filter) {


    return async (dispatch) => {
        try {

            dispatch(
                {
                    type: ActionTypes.SET_SEARCH_FILTERS,
                    payload: filter
                }
            );

        } catch (e) {
            debugger;
        }
    };
}


export function clearSearchFilter() {

    return async (dispatch) => {
        try {

            dispatch(
                {
                    type: ActionTypes.CLEAR_SEARCH_FILTERS,
                    payload: {}
                }
            );

        } catch (e) {
            debugger;
        }
    };
}


export function clearSearchResults() {

    return async (dispatch) => {
        try {

            dispatch(
                {
                    type: ActionTypes.SET_SEARCH_RESULTS,
                    payload: []
                }
            );

        } catch (e) {
            debugger;
        }
    };
}






export function getSearchedInspections(searchFilter) {
    return async (dispatch) => {
        try {

            // 0 - inspectionId
            // 1 - customerId
            // 2 - buildingId - can include building address or corpName
            // 3 - deviceId
            // 4 - elevatorId - elevator companyId

            let searchType = 0;
            let searchId = 0;

            if (searchFilter.inspectionId !== '') {
                searchType = 0;
                searchId = searchFilter.inspectionId;
            }

            if (searchFilter.customer !== '') {
                searchType = 1;
                searchId = searchFilter.customerId;
            }

            if (searchFilter.building !== '') {
                searchType = 2;
                searchId = searchFilter.buildingId;
            }

            if (searchFilter.corpName !== '') {
                searchType = 2;
                searchId = searchFilter.buildingId;
            }

            if (searchFilter.device !== '') {
                searchType = 3;
                searchId = searchFilter.deviceId;
            }

            if (searchFilter.elevator !== '') {
                searchType = 4;
                searchId = searchFilter.elevatorId;
            }



            let request = {
                searchId: searchId,
                searchType: searchType,
                year: searchFilter.year,
                inspectionTypeId: searchFilter.inspTypeId
            };

            let response = await inspectionApi.getSearchedInspection(request);

            dispatch(
                {
                    type: ActionTypes.SET_SEARCH_RESULTS,
                    payload: response.data.results
                }
            );

        } catch (e) {
            debugger;
        }
    };
}


// export function getFilterArrays() { // should we launch the modal?
//     return async (dispatch) => {
//         try {
//             let response = await inspectionApi.getFilterResources();



//             dispatch(
//                 {
//                     type: ActionTypes.SET_FILTER_ARRAYS,
//                     payload: response.data
//                 }
//             );

//         } catch (e) {
//             debugger;
//         }
//     };
// }
