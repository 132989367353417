import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../services/storageService';

// this view decides what internal routes to display
// ...rest is the url params with the route - for navigation



const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    //const api = new APICore();
    // const dispatch = useDispatch();

    // //  check for auth and rehydrate the use profile

    // useEffect(() => {
    //     dispatch(customerActions.clearCustomer());


    // }, []);



    return (
        <Route
            {...rest}

            render={(props) => {

                // check if we have a good token in local storage
                // if not, we will redirect to the login or registration
                if (isAuthenticated() === false) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                }

                // const loggedInUser = api.getLoggedInUser();

                // // check if route is restricted by role
                // if (roles && roles.indexOf(loggedInUser.role) === -1) {
                //     // role not authorized so redirect to home page
                //     return <Redirect to={{ pathname: '/' }} />;
                // }


                // authorized so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
