// @flow
import React from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from '../components/Spinner';

const ModalLoader = ({ isVisible }) => {


    return (
        <>
            <Modal show={isVisible} dialogClassName={'modal-dialog-centered'} backdrop="static">
                <Modal.Body>
                    <div className='text-center overflow-hidden'>
                        <Spinner className="spinner-border-lg" tag="span" color="secondary" />{' '}
                        <h5>Loading, please wait.....</h5>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default ModalLoader;