import { INSPECTION as ActionTypes } from '../actionTypes';
import * as inspectionApi from '../../services/inspectionApi';
import * as buildingApi from '../../services/buildingApi';
import * as deviceApi from '../../services/deviceApi';
import { formatDateShort } from '../../services/utilities';
import moment from 'moment';

export function setInspection(inspId) {

    return async (dispatch) => {
        let payload = {};
        try {
            // go get the inspection graph from the DB
            let response = await inspectionApi.getSingle(inspId);


            payload = mapObject(response.data.result.inspection);
            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );

            return response;
        } catch (e) {
            console.log(e);
        }


    };
}

export function setInspection2(data) {

    return async (dispatch) => {
        let payload = {};
        try {
            // go get the inspection graph from the DB


            //payload = mapObject(data);
            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {
            console.log(e);
        }
    };
}

export function clearInspection() {
    return async (dispatch) => {
        let payload = {};
        try {
            payload = {};
            dispatch(
                {
                    type: ActionTypes.CLEAR_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {
            console.log(e);
        }
    };
}

export function updateSuper(localObject, inspectionId) {
    // update the building super in the api and reload the inspection to get the changes
    return async (dispatch) => {
        let payload = {};
        try {
            let request = {
                inspectionId: inspectionId,
                buildingId: localObject.buildingId,
                name: localObject.name,
                phone: localObject.phone10Digits,
                email: localObject.email
            };

            // result from the update - response with an updated inspection
            let response = await inspectionApi.updateInspectionSuper(request);

            payload = mapObject(response.data.result);

            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {

            console.log(e);
        }
    };
}

export function updateAgent(localObject) {
    // update the building super in the api and reload the inspection to get the changes
    return async (dispatch) => {
        let payload = {};
        try {


            // result from the update - response with an updated inspection
            let response = await inspectionApi.updateInspectionAgent(localObject);

            payload = mapObject(response.data.result);

            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {

            console.log(e);
        }
    };
}

export function insertNote(localObject) {
    // update the building super in the api and reload the inspection to get the changes
    return async (dispatch) => {
        let payload = {};
        try {
            // result from the update - response with an updated inspection
            let response = await inspectionApi.insertNote(localObject);

            payload = mapObject(response.data.result);

            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {

            console.log(e);
        }
    };
}

export function deleteNote(noteId, inspectionId) {
    // update the building super in the api and reload the inspection to get the changes
    return async (dispatch) => {
        let payload = {};
        try {
            // result from the update - response with an updated inspection
            let response = await inspectionApi.deleteNote(noteId, inspectionId);

            payload = mapObject(response.data.result);

            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {

            console.log(e);
        }
    };
}



export function updateInspection(inspection) {

    return async (dispatch) => {
        let payload = {};
        try {
            let propSentDate = moment(inspection.proposalSentDate).format('MM-DD-YYYY');
            if (propSentDate === 'Invalid date') {
                // just wipe out the date, it's invalid
                propSentDate = null;
            }

            let propRecDate = moment(inspection.proposalReceivedDate).format('MM-DD-YYYY');
            if (propRecDate === 'Invalid date') {
                // just wipe out the date, it's invalid
                propRecDate = null;
            }

            let dobNowDate = moment(inspection.dobNotifyDate).format('MM-DD-YYYY');
            if (dobNowDate === 'Invalid date') {
                // just wipe out the date, it's invalid
                dobNowDate = null;
            }

            let request = {
                inspectionId: inspection.inspectionId,
                proposalSentDate: propSentDate,
                proposalReceivedDate: propRecDate,
                dobNotifyDate: dobNowDate,
                dobNowJobNumber: inspection.dobNowJobNumber,
                purchaseOrder: inspection.purchaseOrder,
                misc: inspection.misc,
                proposalYearEnd: inspection.proposalYearEnd,
                pricePerDevice: inspection.pricePerDevice,
                witnessFee: inspection.witnessFee,
                aocTrackingId: inspection.aocTrackingId,
                aocTrackingFee: inspection.aocTrackingFee,
                hasPrePayFilingFee: inspection.hasPrePayFilingFee, // true false, we will convert in api to a 0 or 1
                expeditingFees: inspection.expeditingFees,
                elevatorCompanyId: inspection.elevatorCompanyId,
                elevatorCompanyName: inspection.elevatorCompanyName,
                testingCompanyId: inspection.testingCompanyId,
                testingCompanyName: inspection.testingCompanyName,
                qceTrackingId: inspection.qceTrackingId,
                qceFee: inspection.qceFee
            };


            // result from the update
            let response = await inspectionApi.updateInspection(request);

            payload = mapObject(response.data.result);

            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {

            console.log(e);
        }
    };
}

export function transferInspection(localObject, inspection) {
    return async (dispatch) => {
        let payload = {};
        try {

            // update the building items
            let requestBuilding = {
                buildingId: inspection.buildingId,
                customerFromId: inspection.customerId, // new customer we are transferring to,
                customerToId: localObject.customerId, // new customer we are transferring to,
                agentId: localObject.agentId, // the new default agent
                billingStreet1: localObject.billingStreet1,
                billingStreet2: localObject.billingStreet2,
                billingCity: localObject.billingCity,
                billingState: localObject.billingState,
                billingZip: localObject.billingZip,
                invoiceDeliveryMethod: localObject.invoiceDeliveryMethod
            };
            // make call to building controller to transfer the building
            // it already has salesforce code and email code to update
            buildingApi.transfer(requestBuilding)
                .then(() => {
                    // make call to device controller to update devices with new customer id
                    deviceApi.transfer({
                        buildingId: inspection.buildingId, // what building are we updating
                        customerId: localObject.customerId // new customer we are switching to
                    })
                });



            let request = {
                inspectionId: inspection.inspectionId,
                buildingId: inspection.buildingId,
                customerId: localObject.customerId, // new customer id
                customerNameTo: localObject.customerToName, // new customer name            
                customerNameFrom: inspection.customer.customerName, // old customer name from
                agentId: localObject.agentId,
                pricePerDevice: localObject.pricePerDevice,
                witnessFee: localObject.witnessFee,
                aocTrackingId: localObject.aocTrackingId,
                aocTrackingFee: localObject.aocTrackingFee,
                hasPrePayFilingFee: localObject.hasPrePayFilingFee, // true false, we will convert in api to a 0 or 1
                expeditingFees: localObject.expeditingFees,
                billingStreet1: localObject.billingStreet1,
                billingStreet2: localObject.billingStreet2,
                billingCity: localObject.billingCity,
                billingState: localObject.billingState,
                billingZip: localObject.billingZip,
                invoiceDeliveryMethod: localObject.invoiceDeliveryMethod
            };

            // result from the update
            let response = await inspectionApi.transferInspection(request);

            payload = mapObject(response.data.result);

            dispatch(
                {
                    type: ActionTypes.SET_INSPECTION,
                    payload: payload
                }
            );
        } catch (e) {

            console.log(e);
        }
    };
}




export function mapObject(item) {
    let returnItem = { ...item };


    if (item.proposalSentDate != null || item.proposalSentDate != '') {
        let temp = formatDateShort(item.proposalSentDate);

        if (temp === 'Invalid date') {
            // just wipe out the date, it's invalid
            returnItem.proposalSentDate = '';
        } else {
            returnItem.proposalSentDate = formatDateShort(item.proposalSentDate);
        }
    }

    if (item.proposalReceivedDate != null || item.proposalReceivedDate != '') {
        let temp = formatDateShort(item.proposalReceivedDate);

        if (temp === 'Invalid date') {
            // just wipe out the date, it's invalid
            returnItem.proposalReceivedDate = '';
        } else {
            returnItem.proposalReceivedDate = formatDateShort(item.proposalReceivedDate);
        }
    }

    if (item.dobNotifyDate != null || item.dobNotifyDate != '') {
        let temp = formatDateShort(item.dobNotifyDate);

        if (temp === 'Invalid date') {
            // just wipe out the date, it's invalid            
            returnItem.dobNotifyDate = '';
        } else {
            returnItem.dobNotifyDate = formatDateShort(item.dobNotifyDate);
        }
    }



    return returnItem;
};