// @flow
import React, { useEffect, Suspense } from 'react';
import { Container } from 'react-bootstrap';
//import { useSelector, useDispatch } from 'react-redux';


// import * as buildingActions from '../../redux/actions/buildingActions';
// import * as customerActions from '../../redux/actions/customerActions';
// import * as elevatorActions from '../../redux/actions/elevatorActions';
// import * as boroughActions from '../../redux/actions/boroughActions';
// import * as hoodActions from '../../redux/actions/hoodActions';
// import * as deviceActions from '../../redux/actions/deviceActions';


// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('../Topbar'));
const Navbar = React.lazy(() => import('./Navbar'));

const loading = () => <div className="text-center">loading</div>;

const HorizontalLayout = ({ children }) => {
    // const dispatch = useDispatch();
    // const { dropdownAddressList, dropdownCorpNameList } = useSelector(state => state.buildingReducer);
    // const { dropdownCustomerNameList } = useSelector(state => state.customerReducer);
    // const { dropdownElevatorNameList } = useSelector(state => state.elevatorReducer);
    // const { dropdownBoroughNameList } = useSelector(state => state.boroughReducer);
    // const { dropdownHoodNameList } = useSelector(state => state.hoodReducer);
    // const { dropdownDeviceNameList } = useSelector(state => state.deviceReducer);


    // const dispatch = useDispatch();

    // const [isMenuOpened, setIsMenuOpened] = useState(false);

    // /**
    //  * Open the menu when having mobile screen
    //  */
    // const openMenu = () => {
    //     setIsMenuOpened(!isMenuOpened);
    //     if (document.body) {
    //         if (isMenuOpened) {
    //             document.body.classList.remove('sidebar-enable');
    //         } else {
    //             document.body.classList.add('sidebar-enable');
    //         }
    //     }
    // };

    useEffect(() => {

        // reset app items and resources
        // if (dropdownAddressList && dropdownAddressList.length === 0) {
        //     // get the building address drowndown list
        //     dispatch(buildingActions.getBuildingAddressDropDownList());
        // }

        // if (dropdownCorpNameList && dropdownCorpNameList.length === 0) {
        //     // get the building corp name drowndown list
        //     dispatch(buildingActions.getBuildingCorpNameDropDownList());
        // }

        // if (dropdownCustomerNameList && dropdownCustomerNameList.length === 0) {
        //     // get the customer name drowndown list
        //     dispatch(customerActions.getCustomerNameDropDownList());
        // }

        // if (dropdownElevatorNameList && dropdownElevatorNameList.length === 0) {
        //     // get the elevator name drowndown list
        //     dispatch(elevatorActions.getElevatorNameDropDownList());
        // }

        // if (dropdownBoroughNameList && dropdownBoroughNameList.length === 0) {
        //     // get the borough name drowndown list
        //     dispatch(boroughActions.getBoroughNameDropDownList());
        // }

        // if (dropdownHoodNameList && dropdownHoodNameList.length === 0) {
        //     // get the borough name drowndown list
        //     dispatch(hoodActions.getHoodNameDropDownList());
        // }

        // if (dropdownDeviceNameList && dropdownDeviceNameList.length === 0) {
        //     // get the borough name drowndown list
        //     dispatch(deviceActions.getDeviceNameDropDownList());
        // }



    }, []);

    return (
        <>
            <div className="content">
                <Suspense fallback={loading()}>
                    <Topbar
                        isMenuOpened={false}
                        navCssClasses="topnav-navbar"
                        topbarDark={false}
                    />
                    <Navbar isMenuOpened={false} />

                    <Suspense fallback={loading()}>
                        <Container fluid>
                            {children}
                        </Container>
                    </Suspense>
                </Suspense>




            </div>



            {/* <Suspense fallback={loading()}>
                        <RightSidebar>
                            <ThemeCustomizer />
                        </RightSidebar>
                    </Suspense> */}
        </>
    );
};

export default HorizontalLayout;
