// @flow
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const PageTitle = ({ id, title }) => {
    return (
        <Row>
            <Col xl={12}>
                <h4 className="text-uppercase float-end" style={{ lineHeight: '30px' }}>{id}</h4>
                <h4 className="text-uppercase" style={{ lineHeight: '30px' }}>{title}</h4>
            </Col>
        </Row>
    );
};

export default PageTitle;