// @flow
import React, { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { ELEVATOR_ROUTES, INSPECTION_ROUTES } from '../../routes/app_nav_consts';


import * as elevatorContactActions from '../../redux/actions/elevatorContactActions';
import * as inspectionActions from '../../redux/actions/inspectionActions';
import * as inspectionSearchActions from '../../redux/actions/inspectionSearchActions';
import { INSP_STATUSES, SESSION_STORAGE } from '../../appConsts';


const InspectionTabs = () => {
    const dispatch = useDispatch();
    const { elevator } = useSelector(state => state.elevatorReducer);
    const { inspection } = useSelector(state => state.inspectionReducer);
    const { searchFilter } = useSelector(state => state.inspectionSearchReducer);

    // dig out the url and find out where we are
    const location = useLocation();
    let history = useHistory();

    const [selectedTab, setSelectedTab] = useState('');

    useLayoutEffect(() => {
        // todo: not sure why i need this
        setSelectedTab(location.pathname);
    }, []);


    const changeLocal = (newTab) => {

        // this will get the company elevator contacts
        if (newTab === ELEVATOR_ROUTES.CONTACTS) {
            dispatch(elevatorContactActions.getByCompanyId(elevator.elevatorCompanyId));
        }
        history.push(newTab);
    };

    const exit = () => {
        dispatch(inspectionActions.clearInspection());

        let backUrl = sessionStorage.getItem(SESSION_STORAGE.INSPECTION_NAV_BACK);

        sessionStorage.setItem(SESSION_STORAGE.INSPECTION_ID, 0);

        // check if we need to reload the search screen
        if (backUrl !== INSPECTION_ROUTES.SEARCH)
            // not from the search - just redirect
            history.push(backUrl);
        // go ahead
        dispatch(inspectionSearchActions.getSearchedInspections(searchFilter))
            .then(() => {
                history.push(backUrl);
            });
    };

    return (
        <>

            <ul className="nav nav-tabs nav-bordered">
                <li className="nav-item">
                    <a
                        href="#"
                        onClick={() => changeLocal(INSPECTION_ROUTES.DETAILS)}
                        className={classnames('nav-link', 'py-2', {
                            active: selectedTab === INSPECTION_ROUTES.DETAILS,
                        })}>
                        Details
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        href="#"
                        onClick={() => changeLocal(INSPECTION_ROUTES.EMAIL)}
                        className={classnames('nav-link', 'py-2', {
                            active: selectedTab === INSPECTION_ROUTES.EMAIL,
                        })}>
                        Email Inspection
                    </a>
                </li>

                <li className="nav-item">
                    <a
                        href="#"
                        onClick={() => changeLocal(INSPECTION_ROUTES.UPLOADS)}
                        className={classnames('nav-link', 'py-2', {
                            active: selectedTab === INSPECTION_ROUTES.UPLOADS,
                        })}>
                        Uploads
                    </a>
                </li>

                <li className="nav-item">
                    <a
                        href="#"
                        onClick={() => changeLocal(INSPECTION_ROUTES.CANCEL)}
                        className={classnames('nav-link', 'py-2', {
                            active: selectedTab === INSPECTION_ROUTES.CANCEL,
                        })}>
                        {inspection.statusId === INSP_STATUSES.CANCELED.ID ? 'Uncancel Job' : 'Cancel Job'}
                    </a>
                </li>




                <li className="nav-item">
                    <a
                        href="#"
                        onClick={() => exit()}
                        className={classnames('nav-link', 'py-2')}>
                        Exit
                    </a>
                </li>


            </ul>


        </>
    );
};

export default InspectionTabs;
