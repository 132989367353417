// @flow
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Alert, Button, Modal } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';

// components
import Tabs from './_InspectionTabs';
import Table from '../../components/Table';
import PageTitle from '../../sharedComponents/_PageTitle';


// services
import * as uploadApi from '../../services/uploadApi';
import { INSPECTION_ROUTES } from '../../routes/app_nav_consts';

import ModalLoader from '../../sharedComponents/_ModalLoader';


const InspectionUpload = () => {
    let history = useHistory();

    const { inspection } = useSelector(state => state.inspectionReducer);

    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploadFile, setUploadFile] = useState([]);
    const [fileName, setFileName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [disableUploadButton, setDisableUploadButton] = useState(true);

    useEffect(() => {
        if (inspection.inspectionId === 0) {
            // on hard refresh we are going to the inspection main
            // if we need to reload and land back on the details
            // add insp line id into session storage and rebuild the inspection and the insp line into redux
            history.push(INSPECTION_ROUTES.DETAILS);


        } else {

            fetchUploads();
        };

    }, []);

    const fetchUploads = async () => {
        setIsLoading(true);

        // get the files from azure file storage
        try {
            // use the entity id

            const response = await uploadApi.getAllFilesInDirectory(inspection.inspectionId);
            if (response.data.results.length > 0) {
                setFileList(response.data?.results);
            } else {
                // no files found
                setFileList([]);
            }
            setIsLoading(false);
        } catch (error) {

        }

    }


    const onFileUpload = () => {
        // todo: check if file exists in local file object
        // don't send if we don't have a file

        // user wants to upload the image
        let payload = {
            entityId: inspection.inspectionId,
            shareName: 'inspections',
            file: uploadFile[0]
        };

        uploadApi.uploadFile(payload)
            .then((resp) => {
                fetchUploads();
                setUploadFile([]);
                setDisableUploadButton(true);
            });
    };

    const onDrop = (file) => {
        setUploadFile(file);
        setDisableUploadButton(false);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });





    const launchDeleteModal = (fileName) => {

        setShowDeleteModal(true);
        setFileName(fileName);
    };


    const onFileDelete = () => {
        setShowDeleteModal(false);
        setIsLoading(true);

        // user wants to upload the image
        let payload = {
            entityId: inspection.inspectionId,
            shareName: 'inspections',
            fileName: fileName
        };


        uploadApi.deleteFile(payload)
            .then((resp) => {
                setIsLoading(false);
                setFileName('')
                fetchUploads();
            });
    };

    const LinkCell = ({ row }) => {

        return (
            <a href={row.original.uri} target='_blank'>
                <div className='text-left'>
                    <h3>{row.original.fileName}</h3>
                </div>
            </a>

        );
    };

    const LastModifiedCell = ({ row }) => {

        return (
            <p>{row.original.lastModified && moment(row.original.lastModified).format('MM/DD/yyyy hh:mm:ss A')}</p>
        );
    };


    const ActionCell = ({ row }) => {

        return (
            <div style={{ height: "100%", textAlign: 'center' }}>

                <div className='mb-1 button-list'>
                    <Link to="#" className="text-body fw-bold d-inline-block text-center me-2" onClick={(e) => launchDeleteModal(row.original.fileName)}>
                        <i className="uil uil-trash-alt" style={{ fontSize: '24px' }} ></i><br /><span className="text-uppercase font-10">Delete File</span>
                    </Link>
                </div>
            </div>
        );
    };

    const columns = [
        {
            Header: 'File',
            accessor: 'fileName',
            sort: true,
            Cell: LinkCell,
        },
        {
            Header: 'Last Modified',
            accessor: 'lastModified',
            sort: true,
            Cell: LastModifiedCell,
        },
        {
            Header: 'Modified By',
            accessor: 'createdBy.fullNameFirstLast',
            sort: true,
            //Cell: LastModifiedCell,
        },

        {
            Header: () => {
                return (
                    <div style={{ textAlign: 'center' }}></div>
                );
            },
            accessor: 'f',
            Cell: ActionCell,
        }
    ];

    return (
        <>
            <ModalLoader isVisible={isLoading} />
            {showDeleteModal &&
                <Modal show={showDeleteModal} size={'lg'} dialogClassName={"modal-dialog-centered"} onHide={() => { }} >
                    <Modal.Body>
                        <div className="text-center overflow-hidden">
                            <h5>Delete file?</h5>
                            <p>Are you sure you want to delete this file?</p>
                            <h5>{fileName}</h5>
                            <br />

                            <div className="button-list">
                                <Button className="btn btn-success mb-2 d-inline-block" onClick={() => onFileDelete()} >
                                    Delete
                                </Button>

                                <Button className="btn btn-danger mb-2 d-inline-block" onClick={() => setShowDeleteModal(false)} >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }



            <Row>
                <Col xl={12}>
                    <PageTitle id={`inspection id: ${inspection.inspectionId}`} title={inspection.inspTypeHeaderDisplay + ' Inspection'} />
                </Col>
            </Row>

            <Card>
                <Card.Body className="p-0">
                    <Tabs />

                    <div className="tab-content">
                        <div className="tab-pane show active px-2 pb-3">
                            <div>
                                <div className="mt-3"></div>

                                <Row>
                                    <Col lg={12}>
                                        {uploadFile.length !== 0 && <p>File to upload: {uploadFile[0].name}</p>}
                                        <div className="dropzone">
                                            <div className="dz-message needsclick" {...getRootProps()} >
                                                <i className="h3 text-muted dripicons-cloud-upload" />
                                                <input {...getInputProps()} />
                                                <p>Drag and drop your image here or click select files</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-3"></div>
                                <Row>
                                    <Col>
                                        <div className="float-end button-list">
                                            <Button className={classNames('btn', 'mb-2', 'd-inline-block', 'btn-success')} onClick={() => onFileUpload()} disabled={disableUploadButton}>Upload</Button>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xl={12}>
                                        <h3 className="modal-title">Uploads</h3>
                                        <div className="mt-3"></div>


                                        <Table
                                            columns={columns}
                                            data={fileList}
                                            isSortable={true}
                                            theadClass="table-light"
                                            searchBoxClass="mb-2"
                                        />
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                </Card.Body>
            </Card >

        </>
    );
};

export default InspectionUpload;
