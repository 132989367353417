import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/Inspection';


export const insertInspection = (data) => {

    let url = `${apiRoot}/Insert`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const getSingle = (inspectionId) => {

    let url = `${apiRoot}/GetSingle/${inspectionId}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};

export const getSearchedInspection = (data) => {

    let url = `${apiRoot}/Search`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};


export const updateInspection = (data) => {
    let url = `${apiRoot}/Update`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const transferInspection = (data) => {
    let url = `${apiRoot}/Transfer`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const updateInspectionSuper = (data) => {
    let url = `${apiRoot}/UpdateSuper`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const updateInspectionAgent = (data) => {
    let url = `${apiRoot}/UpdateAgent`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const insertNote = (data) => {
    let url = `${apiRoot}/InsertNote`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const deleteNote = (noteId, inspectionId) => {
    let url = `${apiRoot}/DeleteNote/${noteId}/${inspectionId}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};

export const updateCat5DueDate = (data) => {

    let url = `${apiRoot}/UpdateCat5DueDate`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const checkDoubleBookingStatus = (data) => {
    let url = `${apiRoot}/CheckDoubleBookingStatus`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const emailInspection = (data) => {

    let url = `${apiRoot}/EmailInspection`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};


export const cancelInspection = (data) => {

    let url = `${apiRoot}/CancelInspection`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(data)
    };
    return handleFetch(url, options, true);
};

export const uncancelInspection = (inspectionId) => {

    let url = `${apiRoot}/UncancelInspection/${inspectionId}`;

    const options = {
        method: API_METHOD.GET
    };
    return handleFetch(url, options, true);
};




export const getByCustomer = (customerId, year, isCertificateManagement) => {

    let url = `${apiRoot}/GetInspectionsByCustomer`;

    let payload = {
        customerId: customerId,
        year: year,
        isCertificateManagement: isCertificateManagement
    };


    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };
    return handleFetch(url, options, true);
};