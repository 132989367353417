import { API_METHOD, ENDPOINTS, LOCAL_STORAGE } from '../appConsts';
import { handleFetch } from './handleFetch';

const apiRoot = ENDPOINTS.API + '/inspectionLine';
const apiUploadPath = ENDPOINTS.API + '/upload';


export function uploadInspectionImage(payload) {
    let tok = null;
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    if (token) {
        tok = token.replace(/['"]+/g, '');
    }

    let url = `${apiRoot}/UploadInspectionImage/${payload.inspectionId}/${payload.inspectionLineId}/${payload.partId}/${payload.index}`;

    let formData = new FormData();
    formData.append('file', payload.file);
    const options = {
        method: 'POST',
        body: formData
    };

    return new Promise((resolve, reject) => {

        let fetchOptions = Object.assign(
            {},
            {
                headers: {
                    Authorization: `Bearer ${tok}`
                }
            },
            options
        );

        fetch(url, fetchOptions)
            .then(response => {

                let ErrorHandler = {
                    statusCode: 401,
                    status: 'Unauthorized'
                };

                const contentType = response.headers.get('content-type');

                if (response.status === 401) {
                    return ErrorHandler;
                }
                return contentType && response.json();
            })
            .then(json => {
                resolve(json);
            })
            .catch(error => {

                reject(error);
            });
    });
}


export const clearInspectionImage = (payload) => {

    let url = `${apiRoot}/ClearInspectionImage/${payload.inspectionId}/${payload.inspectionLineId}/${payload.partId}/${payload.index}`;

    const options = {
        method: API_METHOD.GET

    };
    return handleFetch(url, options, true);
};





export const getAllFilesInDirectory = (id) => {

    let payload = {
        shareName: 'inspections',
        entityId: id
    };

    let url = `${apiUploadPath}/GetAllFilesInDirectory`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };

    return handleFetch(url, options, true);
};

export const deleteFile = (payload) => {

    let url = `${apiUploadPath}/DeleteFile`;

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };
    return handleFetch(url, options, true);
};


export function uploadFile(payload) {

    let tok = null;
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    if (token) {
        tok = token.replace(/['"]+/g, '');
    }

    let url = `${apiUploadPath}/UploadFile/${payload.entityId}/${payload.shareName}`;

    let formData = new FormData();
    formData.append('file', payload.file);
    const options = {
        method: 'POST',
        body: formData
    };

    return new Promise((resolve, reject) => {

        let fetchOptions = Object.assign(
            {},
            {
                headers: {
                    Authorization: `Bearer ${tok}`
                }
            },
            options
        );

        fetch(url, fetchOptions)
            .then(response => {

                let ErrorHandler = {
                    statusCode: 401,
                    status: 'Unauthorized'
                };

                const contentType = response.headers.get('content-type');

                if (response.status === 401) {
                    return ErrorHandler;
                }
                return contentType && response.json();
            })
            .then(json => {
                resolve(json);
            })
            .catch(error => {

                reject(error);
            });
    });
}

