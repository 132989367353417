import { ELEVATOR_CONTACT as ActionTypes } from '../actionTypes';
import * as elevatorContactApi from '../../services/elevatorContactApi';

export function getByCompanyId(item) {
    return async (dispatch) => {

        let response = await elevatorContactApi.getByCompanyId(item);
        let payload = response.data.results;

        dispatch(
            {
                type: ActionTypes.SET_ELEVATOR_CONTACT_LIST,
                payload: payload
            }
        );

        return payload
    };
};

export function setContact(item) {
    return async (dispatch) => {

        let payload = { ...item };
        //payload.object = mapObject(item);
        //payload.isNew = isNew;

        dispatch(
            {
                type: ActionTypes.SET_ELEVATOR_CONTACT,
                payload: payload
            }
        );
    };
};

export function clearContact() {
    return async (dispatch) => {

        dispatch(
            {
                type: ActionTypes.SET_ELEVATOR_CONTACT,
                payload: elevatorContactApi.elevatorContactInit
            }
        );
    };
};

export function clearContactList() {
    return async (dispatch) => {

        dispatch(
            {
                type: ActionTypes.SET_ELEVATOR_CONTACT_LIST,
                payload: []
            }
        );
    };
};

export function insert(item, companyId) {

    return async (dispatch) => {

        let temp = { ...item, elevatorCompanyId: companyId };

        let response = await elevatorContactApi.insert(temp);


        // handle errors
        if (response.data.hasError === false) {
            // we are good to go
            dispatch(
                {
                    type: ActionTypes.SET_ELEVATOR_CONTACT,
                    payload: response.data.result
                }
            );

        }
        return response.data;


    };
};

export function update(item) {

    return async (dispatch) => {

        let payload = {};
        let response = await elevatorContactApi.update(item);


        // handle errors
        if (response.data.hasError === false) {
            // we are good to go
            payload = response.data.result;
            dispatch(
                {
                    type: ActionTypes.SET_ELEVATOR_CONTACT,
                    payload: payload
                }
            );

        }
        return response.data;


    };
};