import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';


import PrivateRoute from './PrivateRoute';
import {
    CUSTOMER_ROUTES, BUILDING_ROUTES, AGENT_ROUTES,
    DEVICE_ROUTES, ELEVATOR_ROUTES, INSPECTION_ROUTES,
    ADMIN_ROUTES
} from './app_nav_consts';
import InspectionUpload from '../inspections/details/insp-uploads';


// lazy load all the views



// auth
const Login = React.lazy(() => import('../external/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

const Login2 = React.lazy(() => import('../pages/account2/Login2'));
const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
const Register2 = React.lazy(() => import('../pages/account2/Register2'));
const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('../pages/account2/LockScreen2'));

// dashboard

// apps
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
// - other
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));

const Landing = React.lazy(() => import('../pages/landing'));





// global dashboards
const InspectionDashboard = React.lazy(() => import('../inspections/insp-dashboard'));
const CustomerDashboard = React.lazy(() => import('../customers/customer-dashboard'));
const BuildingDashboard = React.lazy(() => import('../buildings/building-dashboard'));
const AgentDashboard = React.lazy(() => import('../agents/agent-dashboard'));
const DeviceDashboard = React.lazy(() => import('../devices/device-dashboard'));
const ElevatorDashboard = React.lazy(() => import('../elevatorCompanies/elevator-dashboard'));
const AdminDashboard = React.lazy(() => import('../admin/admin-dashboard'));


// inspection module
//const InspectionDetails = React.lazy(() => import('../customers/customer-details'));
const InspectionScheduler = React.lazy(() => import('../inspections/insp-scheduler'));
const InspectionSearch = React.lazy(() => import('../inspections/insp-search'));
const InspectionReports = React.lazy(() => import('../inspections/insp-reports'));
const InspectionDetails = React.lazy(() => import('../inspections/details/insp-details'));
const InspectionLineDetails = React.lazy(() => import('../inspections/details/insp-line-details'));
const InspectionInvoice = React.lazy(() => import('../inspections/insp-invoice'));
const InspectionInvoiceList = React.lazy(() => import('../inspections/invoice/insp-invoice-list'));

const InspectionEmail = React.lazy(() => import('../inspections/details/insp-email'));
const InpsectionUploads = React.lazy(() => import('../inspections/details/insp-uploads'));
const InspectionCancel = React.lazy(() => import('../inspections/details/insp-cancel'));
const InspectionNew = React.lazy(() => import('../inspections/insp-new'));

const InspectionWitness = React.lazy(() => import('../inspections/dashboard/inspection-witness'));

// used for customer module
const CustomerDetails = React.lazy(() => import('../customers/customer-details'));
const CustomerNotes = React.lazy(() => import('../customers/customer-notes'));
const CustomerAgents = React.lazy(() => import('../customers/customer-agents'));
const CustomerBuildings = React.lazy(() => import('../customers/customer-buildings'));
const CustomerDevices = React.lazy(() => import('../customers/customer-devices'));
const CustomerNew = React.lazy(() => import('../customers/customer-new'));
const CustomerInspections = React.lazy(() => import('../customers/customer-inspections'));
const CustomerAnalytics = React.lazy(() => import('../customers/customer-analytics'));
const CustomerCertManagement = React.lazy(() => import('../customers/customer-cert-management'));


// agent module
const AgentDetails = React.lazy(() => import('../agents/agent-details'));
const AgentTransfer = React.lazy(() => import('../agents/agent-transfer'));
const AgentBuildings = React.lazy(() => import('../agents/agent-buildings'));

// building module
const BuildingDetails = React.lazy(() => import('../buildings/building-details'));
const BuildingDevices = React.lazy(() => import('../buildings/building-devices'));
const BuildingTransfer = React.lazy(() => import('../buildings/building-transfer'));


// device module
const DeviceDetails = React.lazy(() => import('../devices/device-details'));

// elevator module
const ElevatorDetails = React.lazy(() => import('../elevatorCompanies/elevator-details'));
const ElevatorContactsDetails = React.lazy(() => import('../elevatorCompanies/elevator-contacts'));

// admin
const ProposalDashboard = React.lazy(() => import('../admin/proposals/proposal-dashboard'));
const Proposals = React.lazy(() => import('../admin/proposals/proposals'));
const ProposalDetails = React.lazy(() => import('../admin/proposals/proposal-details'));

const UsersDashboard = React.lazy(() => import('../admin/users/user-dashboard'));
const UserSearch = React.lazy(() => import('../admin/users/user-search'));
const UserDetails = React.lazy(() => import('../admin/users/details/user-details'));





const HomeDashboard = React.lazy(() => import('../home'));




// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};

const homeRoutes = {
    path: '/home',
    name: 'Home',
    route: PrivateRoute,
    icon: 'uil-calender',
    component: HomeDashboard,
};

const inspectionRoutes = {
    path: '/inspections',
    name: 'Inspections',
    icon: 'uil-home-alt',
    children: [
        {
            path: INSPECTION_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: InspectionDashboard,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.SCHEDULER,
            name: 'Scheduler',
            component: InspectionScheduler,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.SEARCH,
            name: 'Search',
            component: InspectionSearch,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.REPORTS,
            name: 'Reports',
            component: InspectionReports,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.NEW,
            name: 'New',
            component: InspectionNew,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.DETAILS,
            name: 'Details',
            component: InspectionDetails,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.LINE_DETAILS,
            name: 'Details',
            component: InspectionLineDetails,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.EMAIL,
            name: 'Email',
            component: InspectionEmail,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.UPLOADS,
            name: 'Upload',
            component: InpsectionUploads,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.CANCEL,
            name: 'Cancel',
            component: InspectionCancel,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.INVOICE,
            name: 'Invoice',
            component: InspectionInvoice,
            route: PrivateRoute,
        },
        {
            path: INSPECTION_ROUTES.INVOICE_LIST,
            name: 'Invoice List',
            component: InspectionInvoiceList,
            route: PrivateRoute,
        },


        {
            path: INSPECTION_ROUTES.INSPECTION_WITNESS,
            name: 'Inspection Witness',
            component: InspectionWitness,
            route: PrivateRoute,
        },

    ],
};


const adminRoutes = {
    path: '/admin',
    name: 'Admin',
    icon: 'uil-home-alt',
    children: [
        {
            path: ADMIN_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: AdminDashboard,
            route: PrivateRoute,
        },
        {
            path: ADMIN_ROUTES.PROPOSAL_DASHBOARD,
            name: 'ProposalBatches',
            component: ProposalDashboard,
            route: PrivateRoute,
        },
        {
            path: ADMIN_ROUTES.PROPOSAL_PROPOSALS,
            name: 'Proposals',
            component: Proposals,
            route: PrivateRoute,
        },
        {
            path: ADMIN_ROUTES.PROPOSAL_DETAILS,
            name: 'ProposalDetails',
            component: ProposalDetails,
            route: PrivateRoute,
        },
        {
            path: ADMIN_ROUTES.USER_DASHBOARD,
            name: 'UserDashboard',
            component: UsersDashboard,
            route: PrivateRoute,
        },
        {
            path: ADMIN_ROUTES.USER_SEARCH,
            name: 'UserSearch',
            component: UserSearch,
            route: PrivateRoute,
        },
        {
            path: ADMIN_ROUTES.USER_DETAILS,
            name: 'UserDetails',
            component: UserDetails,
            route: PrivateRoute,
        },



    ],
};


const agentRoutes = {
    path: '/agents',
    name: 'Agents',
    icon: 'uil-home-alt',
    children: [
        {
            path: AGENT_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: AgentDashboard,
            route: PrivateRoute,
        },
        {
            path: AGENT_ROUTES.DETAILS,
            name: 'Details',
            component: AgentDetails,
            route: PrivateRoute,
        },
        {
            path: AGENT_ROUTES.TRANSFER,
            name: 'Transfer',
            component: AgentTransfer,
            route: PrivateRoute,
        },
        {
            path: AGENT_ROUTES.BUILDINGS,
            name: 'Buildings',
            component: AgentBuildings,
            route: PrivateRoute,
        },


    ],
};

const customerRoutes = {
    path: CUSTOMER_ROUTES.ROOT,
    name: 'Customers',
    icon: 'uil-home-alt',
    route: PrivateRoute,
    children: [
        {
            path: CUSTOMER_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: CustomerDashboard,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.NEW,
            name: 'New',
            component: CustomerNew,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.DETAILS,
            name: 'Details',
            component: CustomerDetails,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.ANALYTICS,
            name: 'Analytics',
            component: CustomerAnalytics,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.NOTES,
            name: 'Notes',
            component: CustomerNotes,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.AGENTS,
            name: 'Agents',
            component: CustomerAgents,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.BUILDINGS,
            name: 'Buildings',
            component: CustomerBuildings,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.DEVICES,
            name: 'Devices',
            component: CustomerDevices,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.INSPECTIONS,
            name: 'Inspections',
            component: CustomerInspections,
            route: PrivateRoute,
        },
        {
            path: CUSTOMER_ROUTES.CERT_MANAGEMENT,
            name: 'CertManagement',
            component: CustomerCertManagement,
            route: PrivateRoute,
        },
    ],
};

const buildingRoutes = {
    path: BUILDING_ROUTES.ROOT,
    name: 'Buildings',
    icon: 'uil-home-alt',

    children: [
        {
            path: BUILDING_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: BuildingDashboard,
            route: PrivateRoute,
        },
        {
            path: BUILDING_ROUTES.DETAILS,
            name: 'Details',
            component: BuildingDetails,
            route: PrivateRoute,
        },
        {
            path: BUILDING_ROUTES.DEVICES,
            name: 'Devices',
            component: BuildingDevices,
            route: PrivateRoute,
        },
        {
            path: BUILDING_ROUTES.TRANSFER,
            name: 'Transfer',
            component: BuildingTransfer,
            route: PrivateRoute,
        },
    ],
};

const deviceRoutes = {
    path: '/devices',
    name: 'Devices',
    icon: 'uil-home-alt',
    children: [
        {
            path: DEVICE_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: DeviceDashboard,
            route: PrivateRoute,
        },
        {
            path: DEVICE_ROUTES.DETAILS,
            name: 'Details',
            component: DeviceDetails,
            route: PrivateRoute,
        },
    ],
};

const elevatorRoutes = {
    path: '/elevators',
    name: 'Elevators',
    icon: 'uil-home-alt',

    children: [
        {
            path: ELEVATOR_ROUTES.DASHBOARD,
            name: 'Dashboard',
            component: ElevatorDashboard,
            route: PrivateRoute,
        },
        {
            path: ELEVATOR_ROUTES.DETAILS,
            name: 'Details',
            component: ElevatorDetails,
            route: PrivateRoute,
        },
        {
            path: ELEVATOR_ROUTES.CONTACTS,
            name: 'Contacts',
            component: ElevatorContactsDetails,
            route: PrivateRoute,
        },

    ],
};

const appRoutes = [
    homeRoutes,
    adminRoutes,
    inspectionRoutes,
    customerRoutes,
    agentRoutes,
    buildingRoutes,
    deviceRoutes,
    elevatorRoutes,

];



const otherPublicRoutes = [
    {
        path: '/landing',
        name: 'landing',
        component: Landing,
        route: Route,
    },

    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        component: ErrorPageNotFound,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: ServerError,
        route: Route,
    },
    {
        path: '/account/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
];



// auth
const authRoutes = [
    {
        path: '/account/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/account/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/account/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/account/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/account/login2',
        name: 'Login2',
        component: Login2,
        route: Route,
    },
    {
        path: '/account/logout2',
        name: 'Logout2',
        component: Logout2,
        route: Route,
    },
    {
        path: '/account/register2',
        name: 'Register2',
        component: Register2,
        route: Route,
    },
    {
        path: '/account/confirm2',
        name: 'Confirm2',
        component: Confirm2,
        route: Route,
    },
    {
        path: '/account/forget-password2',
        name: 'Forget Password2',
        component: ForgetPassword2,
        route: Route,
    },
    {
        path: '/account/lock-screen2',
        name: 'Lock Screen2',
        component: LockScreen2,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {

    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, ...appRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
